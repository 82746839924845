@import "@design";

.list-title {
  margin: 1em 0 0.5em;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.asset-tile {
  padding: 0;
  line-height: 1.6;
  &:not(:last-child) {
    border-bottom: 1px dotted lighten(black, 80);
  }
  &.assigned {
    // opacity: 0.4;
  }
}
