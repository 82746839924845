@import "@design";

@media print {
  .dispatcher-quick-look {
    padding: 0;
  }
  .col-title {
    padding-top: 0;
  }
  .print-half {
    flex-basis: 50% !important;
  }
}
